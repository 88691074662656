import Image from 'next/image';
import React from 'react';

import { Plate } from '@/shared/ui/desktop/plate';

import COLLABORATION_BLOGGERS_1024 from '../../../../public/assets/marketing-banners/blogers/collaboration-bloggers-1024.webp';
import COLLABORATION_BLOGGERS_1440 from '../../../../public/assets/marketing-banners/blogers/collaboration-bloggers-1440.webp';
import COLLABORATION_BLOGGERS_1920 from '../../../../public/assets/marketing-banners/blogers/collaboration-bloggers-1920.webp';
import PETS_GIVEAWAY_1024 from '../../../../public/assets/marketing-banners/pets-giveaway/pets-giveaway-1024.webp';
import PETS_GIVEAWAY_1440 from '../../../../public/assets/marketing-banners/pets-giveaway/pets-giveaway-1440.webp';
import PETS_GIVEAWAY_1920 from '../../../../public/assets/marketing-banners/pets-giveaway/pets-giveaway-1920.webp';
import BUY_PETS_SAVE_MONEY_1024 from '../../../../public/assets/marketing-banners/playing-user/playing-user-buy-pet-save-1024.webp';
import BUY_PETS_SAVE_MONEY_1440 from '../../../../public/assets/marketing-banners/playing-user/playing-user-buy-pet-save-1440.webp';
import BUY_PETS_SAVE_MONEY_1920 from '../../../../public/assets/marketing-banners/playing-user/playing-user-buy-pet-save-1920.webp';
import BUY_PETS_BE_COOL_2_1024 from '../../../../public/assets/marketing-banners/playing-user/playing-user-buy-pets-be-cool-2-1024.webp';
import BUY_PETS_BE_COOL_2_1440 from '../../../../public/assets/marketing-banners/playing-user/playing-user-buy-pets-be-cool-2-1440.webp';
import BUY_PETS_BE_COOL_2_1920 from '../../../../public/assets/marketing-banners/playing-user/playing-user-buy-pets-be-cool-2-1920.webp';
import BUY_PETS_BE_COOL_1024 from '../../../../public/assets/marketing-banners/playing-user/playing-user-buy-pets-be-cool-1024.webp';
import BUY_PETS_BE_COOL_1440 from '../../../../public/assets/marketing-banners/playing-user/playing-user-buy-pets-be-cool-1440.webp';
import BUY_PETS_BE_COOL_1920 from '../../../../public/assets/marketing-banners/playing-user/playing-user-buy-pets-be-cool-1920.webp';
import CONSTANT_PROMOTION_1024 from '../../../../public/assets/marketing-banners/promotions/constant-promotions-1024.webp';
import CONSTANT_PROMOTION_1440 from '../../../../public/assets/marketing-banners/promotions/constant-promotions-1440.webp';
import CONSTANT_PROMOTION_1920 from '../../../../public/assets/marketing-banners/promotions/constant-promotions-1920.webp';
import SOCIAL_BOOST_1024 from '../../../../public/assets/marketing-banners/social/social-boost-1024.webp';
import SOCIAL_BOOST_1440 from '../../../../public/assets/marketing-banners/social/social-boost-1440.webp';
import SOCIAL_BOOST_1920 from '../../../../public/assets/marketing-banners/social/social-boost-1920.webp';
import WEBSITE_CONCEPT_1_1024 from '../../../../public/assets/marketing-banners/website-concept/website-concept-1-1024.webp';
import WEBSITE_CONCEPT_1_1440 from '../../../../public/assets/marketing-banners/website-concept/website-concept-1-1440.webp';
import WEBSITE_CONCEPT_2_1024 from '../../../../public/assets/marketing-banners/website-concept/website-concept-2-1024.webp';
import WEBSITE_CONCEPT_2_1440 from '../../../../public/assets/marketing-banners/website-concept/website-concept-2-1440.webp';
import WEBSITE_CONCEPT_3_1024 from '../../../../public/assets/marketing-banners/website-concept/website-concept-3-1024.webp';
import WEBSITE_CONCEPT_3_1440 from '../../../../public/assets/marketing-banners/website-concept/website-concept-3-1440.webp';
import { BannerImageProps, BannersByVariant } from './banner-image.types';

const bannersByVariant: BannersByVariant = {
  concept1: {
    small: WEBSITE_CONCEPT_1_1024,
    medium: WEBSITE_CONCEPT_1_1440,
    large: WEBSITE_CONCEPT_1_1440,
  },
  concept2: {
    small: WEBSITE_CONCEPT_2_1024,
    medium: WEBSITE_CONCEPT_2_1440,
    large: WEBSITE_CONCEPT_2_1440,
  },
  concept3: {
    small: WEBSITE_CONCEPT_3_1024,
    medium: WEBSITE_CONCEPT_3_1440,
    large: WEBSITE_CONCEPT_3_1440,
  },
  'social-boost': {
    small: SOCIAL_BOOST_1024,
    medium: SOCIAL_BOOST_1440,
    large: SOCIAL_BOOST_1920,
  },
  'constant-promotion': {
    small: CONSTANT_PROMOTION_1024,
    medium: CONSTANT_PROMOTION_1440,
    large: CONSTANT_PROMOTION_1920,
  },
  'bloggers-collaboration': {
    small: COLLABORATION_BLOGGERS_1024,
    medium: COLLABORATION_BLOGGERS_1440,
    large: COLLABORATION_BLOGGERS_1920,
  },
  'save-money': {
    small: BUY_PETS_SAVE_MONEY_1024,
    medium: BUY_PETS_SAVE_MONEY_1440,
    large: BUY_PETS_SAVE_MONEY_1920,
  },
  'be-cool-1': {
    small: BUY_PETS_BE_COOL_1024,
    medium: BUY_PETS_BE_COOL_1440,
    large: BUY_PETS_BE_COOL_1920,
  },
  'be-cool-2': {
    small: BUY_PETS_BE_COOL_2_1024,
    medium: BUY_PETS_BE_COOL_2_1440,
    large: BUY_PETS_BE_COOL_2_1920,
  },
  'pets-giveaway': {
    small: PETS_GIVEAWAY_1024,
    medium: PETS_GIVEAWAY_1440,
    large: PETS_GIVEAWAY_1920,
  },
};

export const BannerImageEntityDesktop = ({
  variant,
  size,
  className,
}: BannerImageProps) => {
  if (!size) return <Plate variant={'16'} />;

  return (
    <Image
      loading={'eager'}
      className={className}
      src={bannersByVariant[variant][size]}
      priority
      fetchPriority={'high'}
      placeholder="blur"
      blurDataURL="./"
      fill
      quality={100}
      alt={'concept'}
      style={{
        borderRadius: 'inherit',
        objectFit: 'cover',
      }}
    />
  );
};
