'use client';
import { BannerImageEntityDesktop } from '@entities/desktop/banner-image';
import { useQueryString } from '@hooks/client';
import {
  CategoriesWidget,
  ListCardsWidget,
  LiveFeedWidget,
} from '@widgets/desktop';
import cn from 'clsx';
import { useRouter, useSearchParams } from 'next/navigation';
import React, { useEffect, useRef } from 'react';
import { Autoplay, EffectFade } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
import { useIsClient, useMediaQuery } from 'usehooks-ts';

import { SeeAllButton } from '@/shared/components/see-all-button';
import { WithShopItems } from '@/shared/hocs';
import { Label } from '@/shared/ui';
import { Plate } from '@/shared/ui/desktop/plate';
import { useStore } from '@/store/context';

import styles from './page.module.scss';

export const MainDesktop = () => {
  const isLargeScreen = useMediaQuery('(min-width: 1920px)');
  const isMediumScreen = useMediaQuery('(min-width: 1440px)');
  const isClient = useIsClient();
  const auth = useStore().authorization;
  const searchParams = useSearchParams();

  const promocode = searchParams.get('promocode');

  const screenSize = isClient
    ? isLargeScreen
      ? 'large'
      : isMediumScreen
        ? 'medium'
        : 'small'
    : null;

  const router = useRouter();
  const { createQueryString } = useQueryString();
  const topBannersEndRef = useRef<HTMLDivElement | null>(null);

  const handleBottomBannerClick = () =>
    router.push(
      createQueryString({
        pathname: '/shop',
        name: 'sale',
        value: 'hot-sale',
        asMultipleParams: true,
        enableToggle: true,
      }),
    );

  useEffect(() => {
    if (promocode) {
      router.push('/');
      auth.openPromoLanding();
    }
  });

  return (
    <main className={styles['container']}>
      <div className={styles['content']}>
        <LiveFeedWidget />
        <div>
          <div
            className={cn(styles['promo-images'], styles['promo-images--top'])}
          >
            <div className={styles['giveaway-top-banner']}>
              <BannerImageEntityDesktop
                variant={'pets-giveaway'}
                size={screenSize}
              />
            </div>
            <div className={styles['swiper-top-wrapper']}>
              <Swiper
                loop
                slidesPerView={1}
                autoplay={{ delay: 5000 }}
                modules={[Autoplay, EffectFade]}
                effect={'fade'}
                className={'swiper-desktop'}
              >
                <SwiperSlide
                  onClick={() =>
                    topBannersEndRef.current?.scrollIntoView({
                      behavior: 'smooth',
                      block: 'start',
                    })
                  }
                  className={styles['swiper-top-banner']}
                >
                  <BannerImageEntityDesktop
                    variant={'concept1'}
                    size={screenSize}
                  />
                </SwiperSlide>
                <SwiperSlide
                  onClick={() => router.push('/shop')}
                  className={styles['swiper-top-banner']}
                >
                  <BannerImageEntityDesktop
                    variant={'concept2'}
                    size={screenSize}
                  />
                </SwiperSlide>
                <SwiperSlide
                  onClick={() =>
                    topBannersEndRef.current?.scrollIntoView({
                      behavior: 'smooth',
                      block: 'start',
                    })
                  }
                  className={styles['swiper-top-banner']}
                >
                  <BannerImageEntityDesktop
                    variant={'concept3'}
                    size={screenSize}
                  />
                </SwiperSlide>
              </Swiper>
            </div>
          </div>
          <div ref={topBannersEndRef} className={styles['scroll-anchor']} />
        </div>
        <WithShopItems>
          {({ items }) => {
            return (
              <ListCardsWidget
                items={items}
                cardsVariant={'primary'}
                topSlot={
                  <>
                    <Label
                      labelVariant={'yellow'}
                      icon={'fire-square-red'}
                      variant={'primary'}
                    >
                      Hot Sales
                    </Label>
                    <SeeAllButton href={'/shop?sort=popular'} />
                  </>
                }
              />
            );
          }}
        </WithShopItems>

        {/* <div
          className={cn(
            styles['promo-images'],
            styles['promo-images--middle-1'],
          )}
        >
          <Plate variant={'16'} className={styles['promo-plate']} />
        </div>*/}
        <WithShopItems>
          {({ items }) => {
            return (
              <ListCardsWidget
                items={items}
                cardsVariant={'primary'}
                topSlot={
                  <>
                    <Label
                      labelVariant={'black'}
                      icon={'star-circle'}
                      variant={'primary'}
                    >
                      Exclusive items
                    </Label>
                    <SeeAllButton href={'/shop?sort=popular'} />
                  </>
                }
              />
            );
          }}
        </WithShopItems>

        <div
          className={cn(
            styles['promo-images'],
            styles['promo-images--middle-2'],
          )}
        >
          <Plate
            variant={'16'}
            onClick={() => alert('banner click')}
            className={styles['promo-plate']}
            key={'middle-banner-1'}
          >
            <BannerImageEntityDesktop
              variant={'bloggers-collaboration'}
              size={screenSize}
            />
          </Plate>
          <Plate
            variant={'16'}
            onClick={() => alert('banner click')}
            className={styles['promo-plate']}
            key={'middle-banner-2'}
          >
            <BannerImageEntityDesktop
              variant={'constant-promotion'}
              size={screenSize}
            />
          </Plate>
          <Plate
            variant={'16'}
            onClick={() => alert('banner click')}
            className={styles['promo-plate']}
            key={'middle-banner-3'}
          >
            <BannerImageEntityDesktop
              variant={'social-boost'}
              size={screenSize}
            />
          </Plate>
        </div>
        <CategoriesWidget />
        <Swiper
          loop
          spaceBetween={5}
          slidesPerView={1}
          autoplay={{ delay: 5000 }}
          modules={[Autoplay]}
          className={'swiper-desktop'}
        >
          <SwiperSlide
            className={styles['swiper-bottom-banner']}
            onClick={handleBottomBannerClick}
          >
            <BannerImageEntityDesktop variant={'be-cool-1'} size={screenSize} />
          </SwiperSlide>
          <SwiperSlide
            className={styles['swiper-bottom-banner']}
            onClick={handleBottomBannerClick}
          >
            <BannerImageEntityDesktop variant={'be-cool-2'} size={screenSize} />
          </SwiperSlide>
          <SwiperSlide
            className={styles['swiper-bottom-banner']}
            onClick={handleBottomBannerClick}
          >
            <BannerImageEntityDesktop
              variant={'save-money'}
              size={screenSize}
            />
          </SwiperSlide>
        </Swiper>

        <WithShopItems>
          {({ items }) => {
            return (
              <ListCardsWidget
                items={items}
                topSlot={
                  <>
                    <Label
                      labelVariant={'yellow'}
                      icon={'graph-up'}
                      variant={'primary'}
                    >
                      Popular Items
                    </Label>{' '}
                    <SeeAllButton href={'/shop?sort=popular'} />
                  </>
                }
                cardsVariant={'primary'}
              />
            );
          }}
        </WithShopItems>
      </div>
    </main>
  );
};
