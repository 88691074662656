import(/* webpackMode: "eager", webpackExports: ["MainDesktop"] */ "/home/app/client/pb-front/src/app/[locale]/desktop.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["AdvertisingWidgetMobile"] */ "/home/app/client/pb-front/src/widgets/mobile/advertising/ui/advertising.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["CartWidgetMobile"] */ "/home/app/client/pb-front/src/widgets/mobile/cart/ui/cart.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["ContentWidgetMobile"] */ "/home/app/client/pb-front/src/widgets/mobile/content/ui/content.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["FooterWidgetMobile"] */ "/home/app/client/pb-front/src/widgets/mobile/footer/ui/footer.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["HeaderWidgetMobile"] */ "/home/app/client/pb-front/src/widgets/mobile/header/ui/header.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["ItemsWithdrawalWidgetMobile"] */ "/home/app/client/pb-front/src/widgets/mobile/items-withdrawal/ui/items-withdrawal.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["NavigationWidgetMobile"] */ "/home/app/client/pb-front/src/widgets/mobile/navigation/ui/navigation.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["ProfileHistoryWidgetMobile"] */ "/home/app/client/pb-front/src/widgets/mobile/profile-history/ui/profile-history.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["ProfileSettingsWidgetMobile"] */ "/home/app/client/pb-front/src/widgets/mobile/settings/ui/settings.tsx");
